<template>
  <div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-card>
      <b-form-group
        label="Référence de colis"
        label-for="vi-first-name"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="MaximizeIcon" />
          </b-input-group-prepend>
          <b-form-input
            ref="select"
            v-model="ref"
            placeholder="Référence de colis"
            autofocus
            @change="checkOrder"
          />
        </b-input-group>
      </b-form-group>
    </b-card>

    <b-form-group
      v-if="user.role ==='admin'"
      label="Dépots Source"
      class="mt-1"
    >
      <validation-provider
        #default="{ errors }"
        name="repository"
      >
        <v-select
          v-model="sourceRepository"
          :clearable="false"
          placeholder="Dépots Source"
          label="name"
          :options="repositories"
          @input="getOrders"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- table -->
    <div class="d-flex justify-content-between">
      <b-card>
        <h4>Colis en attente d'un transfer {{ orders.length }}</h4>
        <vue-good-table
          :columns="columns"
          :rows="orders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="CheckSquareIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  color="green"
                  @click="addItem(props.row)"
                />
                <b-tooltip
                  title="Ajouter"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','25','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-card class="ml-1">
        <h4>Colis selectionné {{ selected.length }}</h4>
        <vue-good-table
          :columns="columns"
          :rows="selected"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="DeleteIcon"
                  color="red"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="resetItem(props.row)"
                />
                <b-tooltip
                  title="Annuler"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','25','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>

        </vue-good-table>
        <b-form-group
          label="Dépots destination"
          class="mt-1"
        >
          <validation-provider
            #default="{ errors }"
            name="repository"
          >
            <v-select
              v-model="destinationRepository"
              :clearable="false"
              placeholder="Dépots destination"
              label="name"
              :options="repositories"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group class="d-flex justify-content-end">
          <b-button
            variant="gradient-primary"
            class="ml-1 d-inline-block mt-1"
            :class="{ 'disabled-cursor': isLoading || !isReturnVoucherValid }"
            :disabled="isLoading || !isReturnVoucherValid"
            @click="addReturnVoucher"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Enregistrer</span>
          </b-button>
        </b-form-group>
      </b-card>

    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import ok from '@/assets/audio/ok.mp3'
import notexist from '@/assets/audio/notexist.mp3'

export default {
  name: 'CreateInterAgencyReturnVoucher',
  components: {
    ValidationProvider,
    BButton,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BTooltip,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      date: '01-10-2021',
      status: '',
      pageLength: 10,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'BL',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'BL',
          },
          width: '120px',
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher client',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Gouvernorat',
          },
        },
        {
          label: 'Délégation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher délégation',
          },
        },
        {
          label: 'Adresse',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher adresse',
          },
        },
        {
          label: 'Telephone',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher telephone',
          },
        },
      ],
      orders: [],
      selected: [],
      searchTerm: '',
      ref: '',
      user: {},
      isLoading: false,
      repositories: [],
      destinationRepository: '',
      sourceRepository: '',
      ok,
      notexist,
    }
  },
  computed: {
    isReturnVoucherValid() {
      return (
        this.selected.length > 0
        && this.destinationRepository.id !== ''
        && this.sourceRepository !== ''
        && this.sourceRepository.id !== this.destinationRepository.id
      )
    },
  },
  created() {
    this.user = storeAuth.state.user
    this.getRepositories()

    if (this.user.role === 'manager') {
      this.sourceRepository = this.user.repository
      this.getOrders()
    }
  },
  mounted() {
    this.$refs.select.focus()
  },
  methods: {
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    checkOrder() {
      const result = this.orders.find(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === Number(this.ref) || element.id === Number(this.ref)
        }
        return element.id === Number(this.ref)
      })
      if (result) {
        this.addItem(result).then(() => {
          this.ref = ''
          this.playAudio(ok)
        })
      } else {
        this.withTitle('Colis non exist')
        this.playAudio(notexist)
      }
    },
    async getOrders() {
      this.isLoading = true

      const payload = {
        repository: this.sourceRepository.id,
      }

      let url = 'api/orders/get-inter-agency-return'

      if (this.user.role === 'admin') {
        url += `?repository=${payload.repository}`
      } else if (this.user.role === 'manager') {
        payload.repository = undefined // Remove the repository filter for managers
      }

      const { data } = await axios.get(url, { params: payload })
      this.orders = data
      this.selected = []
      this.isLoading = false
    },
    async addReturnVoucher() {
      const obj = {
        orders: this.selected,
        destination_repository: this.destinationRepository.id,
        source_repository: this.sourceRepository,
        user: this.user,
      }
      this.isLoading = true
      await axios.post('/api/return-vouchers/create-inter-agency-return-voucher/', obj).then(() => {
        this.$router.push('/bons-retours/inter-agence')
        this.showToast('success', 'top-center', 'Retour inter transfer ajouté avec succés')
        this.isLoading = false
      })
        .catch(error => {
          this.showToast('danger', 'top-center', error.toString())
          this.isLoading = false
        })
    },
    async addItem(item) {
      this.selected.push(item)
      const i = this.orders.findIndex(element => element.id === item.id)
      this.orders.splice(i, 1)
    },
    async resetItem(item) {
      this.orders.push(item)
      const i = this.selected.findIndex(element => element.id === item.id)
      this.selected.splice(i, 1)
    },
    async getRepositories() {
      if (this.user.role === 'manager') {
        const { data } = await axios.get('/api/repositories/')
        const formatedRepositories = data.filter(repository => repository.id !== this.user.repository)
        this.repositories = formatedRepositories
      } else if (this.user.role === 'admin') {
        const { data } = await axios.get('/api/repositories/')
        this.repositories = data
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
